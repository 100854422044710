import mock from "./mock"
import "./tables/aggrid"
import "./navbar/navbarSearch"
import "./navbar/navbarBookmarkSearch"
import "./apps/userList"
import "./payment/goods"
import "./receivable/data"
import "./partner/data"
import "./partner/detail"
import "./payment/customer_pay"
import "./calculate/current"
import "./calculate/monthly"
import "./order/orderList"
import "./order/refundList"
mock.onAny().passThrough()
