import mock from "../mock"
const data = [
  {
    partner: "와이즈만",
    paymethod: "편도요금 유동형",
    ticket_count: 6565,
    passanger_count: 1750,
    pay_total: 54000000,
    payback_total: 3500000,
    revenue: 50500000,
    receivable: 543000,
    month: "2020-03"
  },
  {
    partner: "수학의 아침 (수내)",
    paymethod: "편도요금 고정형",
    ticket_count: 7890,
    passanger_count: 1249,
    pay_total: 74000000,
    payback_total: 4780000,
    revenue: 69200000,
    receivable: 347000,
    month: "2020-03"
  },
  {
    partner: "몬스터잉글리시",
    paymethod: "월정액",
    ticket_count: 3000,
    passanger_count: 642,
    pay_total: 35000000,
    payback_total: 300000,
    revenue: 34700000,
    receivable: 165000,
    month: "2020-03"
  },
  {
    partner: "이야스콜라",
    paymethod: "편도요금 유동형",
    ticket_count: 1200,
    passanger_count: 345,
    pay_total: 26000000,
    payback_total: 236500,
    revenue: 25763500,
    receivable: 12800,
    month: "2020-03"
  }
]
mock.onGet("/api/calculate/current").reply(200, {
  data
})
