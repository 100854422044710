import React from "react"
import { connect } from "react-redux"
import { useAuth0 } from "../../../authServices/auth0/auth0Service"
import {
  logoutWithJWT,
} from "../../../redux/actions/auth/loginActions"
import NavbarUser from "./NavbarUser"



const UserName = props => {
  let username = ""
  username = "ReverseLab"
  return username
}

const ThemeNavbar = props => {
  const { user } = useAuth0()
  return (
    <React.Fragment>
      <div className="" />
          <div className="">
            <div
              className=""
              id="navbar-mobile"
            >
              <NavbarUser
                handleAppOverlay={props.handleAppOverlay}
                changeCurrentLang={props.changeCurrentLang}
                userName={<UserName userdata={user} {...props} />}
                loggedInWith={
                  props.user !== undefined &&
                  props.user.login.values !== undefined
                    ? props.user.login.values.loggedInWith
                    : null
                }
                logoutWithJWT={props.logoutWithJWT}
              />
            </div>
          </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  return {
    user: state.auth
  }
}

export default connect(mapStateToProps, {
  logoutWithJWT,
  useAuth0
})(ThemeNavbar)
