import mock from "../mock"
const data = [
  {
    name: "김성현",
    partner: "와이즈만",
    contact: "010-1234-8876",
    paymethod: "편도요금 유동형",
    ticketCount: "11",
    amount: "57,500",
    status: "1차",
    action: "알림톡전송",
    payDateTime: "2020-05-03 11:54:21",
    userId: "106",
    partnetId: "21",
    paymethodCode: "1",
    statusCode: "5"
  },
  {
    name: "강동희",
    partner: "와이즈만",
    contact: "010-4802-7748",
    paymethod: "편도요금 유동형",
    ticketCount: "10",
    amount: "55,000",
    status: "수납완료",
    action: "알림톡전송",
    payDateTime: "2020-05-03 13:10:42",
    userId: "104",
    partnetId: "21",
    paymethodCode: "1",
    statusCode: "5"
  },
  {
    name: "이정표",
    partner: "수학의아침 (수내)",
    contact: "010-2674-4541",
    paymethod: "편도요금 고정형",
    ticketCount: "5",
    amount: "25,000",
    status: "2차",
    action: "전화하기",
    payDateTime: "2020-04-26 15:21:55",
    userId: "108",
    partnetId: "24",
    paymethodCode: "1",
    statusCode: "5"
  },
  {
    name: "한효승",
    partner: "수학의아침 (수지)",
    contact: "010-9876-2123",
    paymethod: "편도요금 고정형",
    ticketCount: "5",
    amount: "30,000",
    status: "1차",
    action: "알림톡전송",
    payDateTime: "2020-05-03 12:00:12",
    userId: "105",
    partnetId: "22",
    paymethodCode: "1",
    statusCode: "5"
  },
  
  {
    name: "박지영",
    partner: "몬스터잉글리시",
    contact: "010-7625-1823",
    paymethod: "월정액",
    ticketCount: "6",
    amount: "62,000",
    status: "2차",
    action: "전화하기",
    payDateTime: "2020-05-02 16:32:31",
    userId: "107",
    partnetId: "23",
    paymethodCode: "1",
    statusCode: "5"
  },
  {
    name: "임재용",
    partner: "와이즈만",
    contact: "010-8872-5812",
    paymethod: "편도요금 유동형",
    ticketCount: "10",
    amount: "55,000",
    status: "수납완료",
    action: "알림톡전송",
    payDateTime: "2020-04-26 13:56:52",
    userId: "109",
    partnetId: "21",
    paymethodCode: "1",
    statusCode: "5"
  },
  {
    name: "김중호",
    partner: "수학의아침 (수내)",
    contact: "010-5093-1924",
    paymethod: "편도요금 고정형",
    ticketCount: "10",
    amount: "50,000",
    status: "2차",
    action: "전화하기",
    payDateTime: "2020-04-25 11:21:42",
    userId: "11",
    partnetId: "24",
    paymethodCode: "1",
    statusCode: "5"
  },
  {
    name: "서희경",
    partner: "몬스터잉글리시",
    contact: "010-8371-8371",
    paymethod: "월정액",
    ticketCount: "15",
    amount: "62,000",
    status: "1차",
    action: "알림톡전송",
    payDateTime: "2020-04-25 11:11:42",
    userId: "104",
    partnetId: "23",
    paymethodCode: "1",
    statusCode: "5"
  },
  {
    name: "강기봉",
    partner: "와이즈만",
    contact: "010-8291-9982",
    paymethod: "편도요금 유동형",
    ticketCount: "2",
    amount: "25,000",
    status: "2차",
    action: "전화하기",
    payDateTime: "2020-04-20 09:15:21",
    userId: "110",
    partnetId: "21",
    paymethodCode: "1",
    statusCode: "5"
  },
  {
    name: "김우재",
    partner: "와이즈만",
    contact: "010-1282-4882",
    paymethod: "편도요금 유동형",
    ticketCount: "4",
    amount: "30,000",
    status: "1차",
    action: "알림톡전송",
    payDateTime: "2020-04-19 20:21:47",
    userId: "111",
    partnetId: "21",
    paymethodCode: "1",
    statusCode: "5"
  },
  {
    name: "최성우",
    partner: "와이즈만",
    contact: "010-4928-8312",
    paymethod: "편도요금 유동형",
    ticketCount: "10",
    amount: "55,000",
    status: "수납완료",
    action: "알림톡전송",
    payDateTime: "2020-04-19 09:56:01",
    userId: "112",
    partnetId: "21",
    paymethodCode: "1",
    statusCode: "5"
  },
]
mock.onGet("/api/payment/receivable").reply(200, {
  data
})
