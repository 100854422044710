import mock from "../mock"
const data = {
    id: 1,
    data:{
      partner: "",
      area: "",
      paymethod:"무제한 요금제",
      amount: 57000,
    }
  }

mock.onGet("/api/partner/detail").reply(200, {
  data
})
