import axios from "axios"
import { history } from "../history"
import { toast } from "react-toastify"

class Request {
    constructor() {
        this.token = window.sessionStorage.getItem("access_token")
    }
    getHeader = () => {
        let headers = {
            "Client-Type": "web",
            "Content-Type" : "application/json",
            "Access-Token" : (this.token !== undefined) ? this.token : ""
        }
        return headers
    }

    executeError = (error) => {
        console.log(error)
        if (error.response !== undefined) {
            console.log(error.response.data)
            if (error.response.status === 401) {
                toast.error("로그인 세션이 만료되었습니다.", {autoClose: 3000})
                window.sessionStorage.removeItem("access_token")
                history.push("/pages/login")
            }
        }
    }

    get = (url, request) => {
        let callback = request.callback
        let errorCallback = request.errorCallback
        let params = request.params
        axios.get(process.env.REACT_APP_API_HOST+url,
            {
                headers : this.getHeader(),
                params : (params !== undefined) ? params : {}
            }
        ).then(response => {
            callback(response)
        }).catch((error) => {
            this.executeError(error)
            if (errorCallback !== undefined) {
                errorCallback(error)
            }
            if (error.response !== undefined) {
                if (error.response.status !== 401) {
                    toast.error("서버요청 오류가 발생했습니다 : error code["+error.response.data.error_code+"]", {autoClose: 3000})
                }
            } else {
                toast.error("서버요청 오류가 발생했습니다.", {autoClose: 3000})
            }
        })
    }

    post = (url, request) => {
        let data = request.data
        let callback = request.callback
        let errorCallback = request.errorCallback
        axios.post(process.env.REACT_APP_API_HOST+url,
            (data !== undefined) ? data : null,
            {
                headers : this.getHeader()
            }
        ).then(response => {
            callback(response)
        }).catch((error) => {
            this.executeError(error)
            if (errorCallback !== undefined) {
                errorCallback(error)
            }
            if (error.response !== undefined) {
                if (error.response.status !== 401) {
                    toast.error("서버요청 오류가 발생했습니다 : error code["+error.response.data.error_code+"]", {autoClose: 3000})
                }
            } else {
                toast.error("서버요청 오류가 발생했습니다.", {autoClose: 3000})
            }
        })
    }

  put = (url, request) => {
    let data = request.data
    let callback = request.callback
    let errorCallback = request.errorCallback
    axios.put(process.env.REACT_APP_API_HOST+url,
      (data !== undefined) ? data : null,
      {
        headers : this.getHeader()
      }
    ).then(response => {
      callback(response)
    }).catch((error) => {
      this.executeError(error)
      if (errorCallback !== undefined) {
        errorCallback(error)
      }
      if (error.response !== undefined) {
        if (error.response.status !== 401) {
          toast.error("서버요청 오류가 발생했습니다 : error code["+error.response.data.error_code+"]", {autoClose: 3000})
        }
      } else {
        toast.error("서버요청 오류가 발생했습니다.", {autoClose: 3000})
      }
    })
  }

    delete = (url, request) => {
        let data = request.data
        let callback = request.callback
        let errorCallback = request.errorCallback
        axios.delete(process.env.REACT_APP_API_HOST+url,
            {
                data: (data !== undefined) ? data : {},
                headers : this.getHeader()
            }
        ).then(response => {
            callback(response)
        }).catch((error) => {
            this.executeError(error)
            if (errorCallback !== undefined) {
                errorCallback(error)
            }
            if (error.response !== undefined) {
                if (error.response.status !== 401) {
                    toast.error("서버요청 오류가 발생했습니다 : error code["+error.response.data.error_code+"]", {autoClose: 3000})
                }
            } else {
                toast.error("서버요청 오류가 발생했습니다.", {autoClose: 3000})
            }
        })
    }
}


export default Request
