import React from "react"
import { Navbar } from "reactstrap"
import { connect } from "react-redux"
import { useAuth0 } from "../../../authServices/auth0/auth0Service"
import {
  logoutWithJWT,
} from "../../../redux/actions/auth/loginActions"
import NavbarBookmarks from "./NavbarBookmarks"
/*
import NavbarUser from "./NavbarUser"
import userImg from "../../../assets/img/portrait/small/avatar-s-11.jpg"

*/

/*
const UserName = props => {
  let username = ""
/!*  if (props.userdata !== undefined) {
    username = props.userdata.name
  } else if (props.user.login.values !== undefined) {
    username = props.user.login.values.name
    if (
      props.user.login.values.loggedInWith !== undefined &&
      props.user.login.values.loggedInWith === "jwt"
    ) {
      username = props.user.login.values.loggedInUser.name
    }
  } else {
    username = "ReverseLab"
  }*!/

  username = "ReverseLab"
  return username
}*/


const ThemeNavbar = props => {
  return (
    <React.Fragment>
      <div className="content-overlay" />
      <Navbar
        className="header-navbar">
          <div className="navbar-container content">
            <div id="navbar-mobile">
              <div className="bookmark-wrapper">
                <NavbarBookmarks
                  sidebarVisibility={props.sidebarVisibility}
                  handleAppOverlay={props.handleAppOverlay}
                />
              </div>
              {props.horizontal ? (
                <div className="logo d-flex align-items-center">
                  <div className="brand-logo mr-50"/>
                </div>
              ) : null}
            </div>
          </div>
      </Navbar>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  return {
    user: state.auth
  }
}

export default connect(mapStateToProps, {
  logoutWithJWT,
  useAuth0
})(ThemeNavbar)
