import mock from "../mock"
const data = [
  {
    id: 1,
    weekday: "월",
    departure_time: "12:30",
    arrival_time: "12:30",
    departure: "야탑중학교",
    arrival: "리버스랩",
    service_start_date: "2020.03.02",
    service_end_date: "2020.03.31",
    useCount: 2
  },
  {
    id: 2,
    weekday: "월",
    departure_time: "15:20",
    arrival_time: "16:00",
    departure: "리버스랩",
    arrival: "야탑중학교",
    service_start_date: "2020.03.02",
    service_end_date: "2020.03.31",
    useCount: 2
  },
  {
    id: 3,
    weekday: "수",
    departure_time: "12:00",
    arrival_time: "12:30",
    departure: "야탑중학교",
    arrival: "리버스랩",
    service_start_date: "2020.03.02",
    service_end_date: "2020.03.31",
    useCount: 2
  },
  {
    id: 4,
    weekday: "수",
    departure_time: "15:20",
    arrival_time: "16:00",
    departure: "리버스랩",
    arrival: "야탑중학교",
    service_start_date: "2020.03.02",
    service_end_date: "2020.03.31",
    useCount: 2
  },
]
mock.onGet("/api/payment/goods").reply(200, {
  data
})
