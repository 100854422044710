import Request from "./request"

class LoginRequest extends Request {
    
    login = (account, callback, errorCallback) => {
        const request = {
            callback: callback,
            errorCallback: errorCallback,
            data: { id: account.email, password: account.password }
        }
        this.post("/remora/v1/login",request)
    }
}

export default LoginRequest