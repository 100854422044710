const initialState = {
  order_info: {},
  daily_ticket: {},
  daily_ticket_list: [],
  payment: {},
  course: [],
  ticket: {},
  mode: "",
  sidebar: false
}

export const selectedTicket = (state = initialState, action) => {
  switch (action.type) {
    case "RESET_TICKET_VIEW":
      console.log("RESET_TICKET_VIEW")
      return {
        order_info: {},
        daily_ticket: {},
        daily_ticket_list: [],
        payment: {},
        course: [],
        ticket: {},
        mode: "",
        sidebar: false
      }
    case "SELECT_TICKET":
      console.log("SELECT_TICKET")
      return {
        ...state,
        order_info: {},
        payment: {},
        ticket: action.ticket,
        mode: "SELECT_TICKET",
        sidebar: false
      }
    case "SHOW_DETAIL_VIEW":
      console.log("SHOW_DETAIL_VIEW")
      return {
        ...state,
        order_info: action.order_info,
        daily_ticket_list: action.daily_ticket_list,
        course: action.course,
        payment: action.payment,
        mode: action.mode,
        sidebar: true
      }
    case "SET_PAYMENT_DATA":
      console.log("SET_PAYMENT_DATA")
      return {
        ...state,
        payment: action.payment
      }
    case "SHOW_DAILY_COURSE_VIEW":
      console.log("SHOW_DAILY_COURSE_VIEW")
      return {
        ...state,
        daily_ticket: action.daily_ticket,
        mode: action.mode,
        course: action.course,
        sidebar: true
      }
    case "REFUND_COMPLETED_TICKET":
      return {
        ...state,
        order_info: {},
        payment: {},
        ticket: action.ticket,
        mode: "REFUND_COMPLETED_TICKET", 
        result: action.result,
        sidebar: false
      }
    default:
      /*console.log("default")*/
      return state
  }
}

