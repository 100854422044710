import mock from "../mock"
const data = [
  {
    id: 1,
    orderNumber:4000702909090,
    orderDate: "2020-07-02",
    ordertime: "11:13:11",
    refundDate: "2020-11-02",
    realBuyer:"홍길동",
    orderPerson:"홍길동의 아버지",
    contactNumber:"010-1234-5678",
    realAmount:40000,
    FeeAmount:9000,
    Amount:31000,
    paymentMethod:"신용카드",
    TransactionType:"3개월",
    TransactionStatus:"승인실패",
    refundperson:"잔다르크"
  },
  {
    id: 2,
    orderNumber:5000702909091,
    orderDate: "2020-07-02",
    ordertime: "11:13:11",
    refundDate: "2020-11-02",
    realBuyer:"홍길동",
    orderPerson:"홍길동의 아버지",
    contactNumber:"010-1234-5678",
    realAmount:40000,
    FeeAmount:9000,
    Amount:31000,
    paymentMethod:"신용카드",
    TransactionType:"3개월",
    TransactionStatus:"승인실패",
    refundperson:"잔다르크"
  },
  {
    id: 3,
    orderNumber:9000702909092,
    orderDate: "2020-07-02",
    ordertime: "11:13:11",
    refundDate: "2020-11-02",
    realBuyer:"홍길동",
    orderPerson:"홍길동의 아버지",
    contactNumber:"010-1234-5678",
    realAmount:40000,
    FeeAmount:9000,
    Amount:31000,
    paymentMethod:"신용카드",
    TransactionType:"3개월",
    TransactionStatus:"승인실패",
    refundperson:"잔다르크"

  },
  {
    id: 4,
    orderNumber:2000702909093,
    orderDate: "2020-07-02",
    ordertime: "11:13:11",
    refundDate: "2020-11-02",
    realBuyer:"홍길동",
    orderPerson:"홍길동의 아버지",
    contactNumber:"010-1234-5678",
    realAmount:40000,
    FeeAmount:9000,
    Amount:31000,
    paymentMethod:"신용카드",
    TransactionType:"3개월",
    TransactionStatus:"승인실패",
    refundperson:"잔다르크"
  },
  {
    id: 5,
    orderNumber:2000702909094,
    orderDate: "2020-07-02",
    ordertime: "11:13:11",
    refundDate: "2020-11-02",
    realBuyer:"홍길동",
    orderPerson:"홍길동의 아버지",
    contactNumber:"010-1234-5678",
    realAmount:40000,
    FeeAmount:9000,
    Amount:31000,
    paymentMethod:"신용카드",
    TransactionType:"3개월",
    TransactionStatus:"승인실패",
    refundperson:"잔다르크"
  },
  {
    id: 6,
    orderNumber:2000702909090,
    orderDate: "2020-07-02",
    ordertime: "11:13:11",
    refundDate: "2020-11-02",
    realBuyer:"홍길동",
    orderPerson:"홍길동의 아버지",
    contactNumber:"010-1234-5678",
    realAmount:40000,
    FeeAmount:9000,
    Amount:31000,
    paymentMethod:"신용카드",
    TransactionType:"3개월",
    TransactionStatus:"승인실패",
    refundperson:"잔다르크"
  },
  {
    id: 7,
    orderNumber:2000702909091,
    orderDate: "2020-07-02",
    ordertime: "11:13:11",
    refundDate: "2020-11-02",
    realBuyer:"홍길동",
    orderPerson:"홍길동의 아버지",
    contactNumber:"010-1234-5678",
    realAmount:40000,
    FeeAmount:9000,
    Amount:31000,
    paymentMethod:"신용카드",
    TransactionType:"3개월",
    TransactionStatus:"승인실패",
    refundperson:"잔다르크"
  },
  {
    id: 8,
    orderNumber:2000702909092,
    orderDate: "2020-07-02",
    ordertime: "11:13:11",
    refundDate: "2020-11-02",
    realBuyer:"홍길동",
    orderPerson:"홍길동의 아버지",
    contactNumber:"010-1234-5678",
    realAmount:40000,
    FeeAmount:9000,
    Amount:31000,
    paymentMethod:"신용카드",
    TransactionType:"3개월",
    TransactionStatus:"승인실패",
    refundperson:"잔다르크"

  },
  {
    id: 9,
    orderNumber:2000702909093,
    orderDate: "2020-07-02",
    ordertime: "11:13:11",
    refundDate: "2020-11-02",
    realBuyer:"홍길동",
    orderPerson:"홍길동의 아버지",
    contactNumber:"010-1234-5678",
    realAmount:40000,
    FeeAmount:9000,
    Amount:31000,
    paymentMethod:"신용카드",
    TransactionType:"3개월",
    TransactionStatus:"승인실패",
    refundperson:"잔다르크"
  },
  {
    id: 10,
    orderNumber:2000702909093,
    orderDate: "2020-07-02",
    ordertime: "11:13:11",
    refundDate: "2020-11-02",
    realBuyer:"홍길동",
    orderPerson:"홍길동의 아버지",
    contactNumber:"010-1234-5678",
    realAmount:40000,
    FeeAmount:9000,
    Amount:31000,
    paymentMethod:"신용카드",
    TransactionType:"3개월",
    TransactionStatus:"승인실패",
    refundperson:"잔다르크"
  },
  {
    id: 11,
    orderNumber:2000702909090,
    orderDate: "2020-07-02",
    ordertime: "11:13:11",
    refundDate: "2020-11-02",
    realBuyer:"홍길동",
    orderPerson:"홍길동의 아버지",
    contactNumber:"010-1234-5678",
    realAmount:40000,
    FeeAmount:9000,
    Amount:31000,
    paymentMethod:"신용카드",
    TransactionType:"3개월",
    TransactionStatus:"승인실패",
    refundperson:"잔다르크"
  },
  {
    id: 12,
    orderNumber:2000702909091,
    orderDate: "2020-07-02",
    ordertime: "11:13:11",
    refundDate: "2020-11-02",
    realBuyer:"홍길동",
    orderPerson:"홍길동의 아버지",
    contactNumber:"010-1234-5678",
    realAmount:40000,
    FeeAmount:9000,
    Amount:31000,
    paymentMethod:"신용카드",
    TransactionType:"3개월",
    TransactionStatus:"승인실패",
    refundperson:"잔다르크"
  },
  {
    id: 13,
    orderNumber:2000702909092,
    orderDate: "2020-07-02",
    ordertime: "11:13:11",
    refundDate: "2020-11-02",
    realBuyer:"홍길동",
    orderPerson:"홍길동의 아버지",
    contactNumber:"010-1234-5678",
    realAmount:40000,
    FeeAmount:9000,
    Amount:31000,
    paymentMethod:"신용카드",
    TransactionType:"3개월",
    TransactionStatus:"승인실패",
    refundperson:"잔다르크"

  },
  {
    id: 14,
    orderNumber:2000702909093,
    orderDate: "2020-07-02",
    ordertime: "11:13:11",
    refundDate: "2020-11-02",
    realBuyer:"홍길동",
    orderPerson:"홍길동의 아버지",
    contactNumber:"010-1234-5678",
    realAmount:40000,
    FeeAmount:9000,
    Amount:31000,
    paymentMethod:"신용카드",
    TransactionType:"3개월",
    TransactionStatus:"승인실패",
    refundperson:"잔다르크"
  },
  {
    id: 15,
    orderNumber:2000702909094,
    orderDate: "2020-07-02",
    ordertime: "11:13:11",
    refundDate: "2020-11-02",
    realBuyer:"홍길동",
    orderPerson:"홍길동의 아버지",
    contactNumber:"010-1234-5678",
    realAmount:40000,
    FeeAmount:9000,
    Amount:31000,
    paymentMethod:"신용카드",
    TransactionType:"3개월",
    TransactionStatus:"승인실패",
    refundperson:"잔다르크"
  },
  {
    id: 16,
    orderNumber:2000702909090,
    orderDate: "2020-07-02",
    ordertime: "11:13:11",
    refundDate: "2020-11-02",
    realBuyer:"홍길동",
    orderPerson:"홍길동의 아버지",
    contactNumber:"010-1234-5678",
    realAmount:40000,
    FeeAmount:9000,
    Amount:31000,
    paymentMethod:"신용카드",
    TransactionType:"3개월",
    TransactionStatus:"승인실패",
    refundperson:"잔다르크"
  },
  {
    id: 17,
    orderNumber:2000702909091,
    orderDate: "2020-07-02",
    ordertime: "11:13:11",
    refundDate: "2020-11-02",
    realBuyer:"홍길동",
    orderPerson:"홍길동의 아버지",
    contactNumber:"010-1234-5678",
    realAmount:40000,
    FeeAmount:9000,
    Amount:31000,
    paymentMethod:"신용카드",
    TransactionType:"3개월",
    TransactionStatus:"승인실패",
    refundperson:"잔다르크"
  },
  {
    id: 18,
    orderNumber:2000702909092,
    orderDate: "2020-07-02",
    ordertime: "11:13:11",
    refundDate: "2020-11-02",
    realBuyer:"홍길동",
    orderPerson:"홍길동의 아버지",
    contactNumber:"010-1234-5678",
    realAmount:40000,
    FeeAmount:9000,
    Amount:31000,
    paymentMethod:"신용카드",
    TransactionType:"3개월",
    TransactionStatus:"승인실패",
    refundperson:"잔다르크"

  },
  {
    id: 19,
    orderNumber:2000702909093,
    orderDate: "2020-07-02",
    ordertime: "11:13:11",
    refundDate: "2020-11-02",
    realBuyer:"홍길동",
    orderPerson:"홍길동의 아버지",
    contactNumber:"010-1234-5678",
    realAmount:40000,
    FeeAmount:9000,
    Amount:31000,
    paymentMethod:"신용카드",
    TransactionType:"3개월",
    TransactionStatus:"승인실패",
    refundperson:"잔다르크"
  },
  {
    id: 20,
    orderNumber:2000702909094,
    orderDate: "2020-07-02",
    ordertime: "11:13:11",
    refundDate: "2020-11-02",
    realBuyer:"홍길동",
    orderPerson:"홍길동의 아버지",
    contactNumber:"010-1234-5678",
    realAmount:40000,
    FeeAmount:9000,
    Amount:31000,
    paymentMethod:"신용카드",
    TransactionType:"3개월",
    TransactionStatus:"승인실패",
    refundperson:"잔다르크"
  },
  {
    id: 21,
    orderNumber:2000702909093,
    orderDate: "2020-07-02",
    ordertime: "11:13:11",
    refundDate: "2020-11-02",
    realBuyer:"홍길동",
    orderPerson:"홍길동의 아버지",
    contactNumber:"010-1234-5678",
    realAmount:40000,
    FeeAmount:9000,
    Amount:31000,
    paymentMethod:"신용카드",
    TransactionType:"3개월",
    TransactionStatus:"승인실패",
    refundperson:"잔다르크"
  },
]
mock.onGet("/api/order/refund").reply(200, {
  data
})
