import { history } from "../../../history"
// import axios from "axios"
/*import {toast} from "react-toastify";*/
import "react-toastify/dist/ReactToastify.css"
import LoginRequest from "http/login"

/*LOGIN*/
export const loginWithJWT = (user, callback) => {

    return dispatch => {
      dispatch({
        type: "LOGIN_WITH_JWT",
        payload: { }
      })

      const requestCallback = (response) => {
        var loggedInUser
        if (response.data) {
          window.sessionStorage.setItem("access_token", response.data.token)
          loggedInUser = response.data
          dispatch({
            type: "LOGIN_WITH_JWT_SUCCESS",
            payload: {
              loggedInUser,
              loggedInWith: "jwt"
            }
          })
          callback(response, null)
        }
      }

      const errorCallback = (error) => {
        dispatch({
          type: "LOGIN_WITH_JWT_FAILURE",
          payload: { error }
        })
        callback(error.response, error)
      }

      let request = new LoginRequest()
      request.login(user, requestCallback, errorCallback)

    }
  }



/*LOGOUT*/
export const logoutWithJWT = () => {
    return dispatch => {
      window.sessionStorage.removeItem("access_token")
      console.log("LOG OUT")
      dispatch({
        type: "LOGOUT_WITH_JWT",
        payload: { }
      })
      history.push("/pages/login")
    }
  }
export const changeRole = role => {
    return dispatch => dispatch({
      type: "CHANGE_ROLE", userRole: role})
  }



export const getStatus = () =>{
  return dispatch =>{
    dispatch({ type: "GET_STATUS" })
  }
}
export const getStatusSuccess = (data) =>{
  return dispatch =>{
    dispatch({ type: "GET_STATUS_SUCCESS", data})
  }
}
export const getStatusFailure = () =>{
  return dispatch =>{
    dispatch({ type: "GET_STATUS_FAILURE" })
  }
}


















