export const login = (state = { userRole: "admin" }, action) => {
  switch (action.type) {
    case "LOGIN_WITH_JWT": {
      return { ...state, values: action.payload,
               login : {status: 'WAITING'}}
    }
    case "LOGIN_WITH_JWT_SUCCESS": {
      return {...state, values: action.payload, 
                token: action.payload.loggedInUser.token,
                status: 'SUCCESS'}
    }
    case "LOGIN_WITH_JWT_FAILURE": {
      return { ...state, values: action.payload,
                status: 'SUCCESS'}
    }
    case "LOGOUT_WITH_JWT": {
      return { ...state, values: action.payload,
                token: "",
                status: 'LOGOUT'}
    }
    case "CHANGE_ROLE": {
      return { ...state, userRole: action.userRole }
    }
    case  "GET_STATUS" : {
      return { ...state, values: action.payload, isLoggedIn: true }
      }
    case  "GET_STATUS_SUCCESS" : {
      return { ...state, values: action.payload, valid: true, currentUser: action.data}
      }
    case  "GET_STATUS_FAILURE" : {
      return { ...state, values: action.payload,  valid: false, isLoggedIn: false }
    }
    default: {
      return state
    }
  }
}
