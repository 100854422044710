import mock from "../mock"
const data = [
  {
    id: 1,
    orderNumber:20200506113409,
    orderDatetime: "20200506113409",
    orderPerson:"홍길동의 보호자",
    realBuyer:"유관순",
    contactNumber:"01012345678",
    realAmount:53500,
    paymentMethod:"신용카드",
    paymentStatus:"결제확인",
  },
  {
    id: 2,
    orderNumber:5000702909091,
    orderDatetime: "20200507113409",
    orderPerson:"왕건",
    realBuyer:"궁",
    contactNumber:"01022349444",
    realAmount:55500,
    paymentMethod:"  000휴대폰",
    paymentStatus:"결제확인",
  },
  {
    id: 3,
    orderNumber:9000702909092,
    orderDatetime: "20200506113409",
    orderPerson:"아이유",
    realBuyer:"유인나",
    contactNumber:"010-3234-9444",
    realAmount:53500,
    paymentMethod:"무통장입금",
    paymentStatus:"결제확인",

  },
  {
    id: 4,
    orderNumber:2000702909093,
    orderDatetime: "20200506113409",
    orderPerson:"뽀로로",
    realBuyer:"크롱",
    contactNumber:"010-4234-9444",
    realAmount:53500,
    paymentMethod:"신용카드",
    paymentStatus:"부분환불",
  },
  {
    id: 5,
    orderNumber:2000702909094,
    orderDatetime: "20200506113409",
    orderPerson:"보라돌이",
    realBuyer:"뽀",
    contactNumber:"010-1234-9444",
    realAmount:53500,
    paymentMethod:"신용카드",
    paymentStatus:"입금대기",
  },
  {
    id: 6,
    orderNumber:2000702909090,
    orderDatetime: "20200506113409",
    orderPerson:"홍길동",
    realBuyer:"유관순",
    contactNumber:"010-1234-9444",
    realAmount:53500,
    paymentMethod:"신용카드",
    paymentStatus:"환불완료",
  },
  {
    id: 7,
    orderNumber:2000702909091,
    orderDatetime: "20200506113409",
    orderPerson:"왕건",
    realBuyer:"궁",
    contactNumber:"010-1234-9444",
    realAmount:53500,
    paymentMethod:"신용카드",
    paymentStatus:"결제확인",
  },
  {
    id: 8,
    orderNumber:2000702909092,
    orderDatetime: "20200506113409",
    orderPerson:"아이유",
    realBuyer:"유인나",
    contactNumber:"010-1234-9444",
    realAmount:53500,
    paymentMethod:"신용카드",
    paymentStatus:"결제확인",

  },
  {
    id: 9,
    orderNumber:2000702909093,
    orderDatetime: "20200506113409",
    orderPerson:"뽀로로",
    realBuyer:"크롱",
    contactNumber:"010-1234-9444",
    realAmount:53500,
    paymentMethod:"신용카드",
    paymentStatus:"결제확인",
  },
  {
    id: 10,
    orderNumber:2000702909094,
    orderDatetime: "20200506113409",
    orderPerson:"보라돌이",
    realBuyer:"뽀",
    contactNumber:"010-1234-9444",
    realAmount:53500,
    paymentMethod:"신용카드",
    paymentStatus:"결제확인",
  },
  {
    id: 11,
    orderNumber:2000702909090,
    orderDatetime: "20200506113409",
    orderPerson:"홍길동",
    realBuyer:"유관순",
    contactNumber:"010-1234-9444",
    realAmount:53500,
    paymentMethod:"신용카드",
    paymentStatus:"결제확인",
  },
  {
    id: 12,
    orderNumber:2000702909091,
    orderDatetime: "20200506113409",
    orderPerson:"왕건",
    realBuyer:"궁",
    contactNumber:"010-1234-9444",
    realAmount:53500,
    paymentMethod:"신용카드",
    paymentStatus:"결제확인",
  },
  {
    id: 13,
    orderNumber:2000702909092,
    orderDatetime: "20200506113409",
    orderPerson:"아이유",
    realBuyer:"유인나",
    contactNumber:"010-1234-9444",
    realAmount:53500,
    paymentMethod:"신용카드",
    paymentStatus:"결제확인",

  },
  {
    id: 14,
    orderNumber:2000702909093,
    orderDatetime: "20200506113409",
    orderPerson:"뽀로로",
    realBuyer:"크롱",
    contactNumber:"010-1234-9444",
    realAmount:53500,
    paymentMethod:"신용카드",
    paymentStatus:"결제확인",
  },
  {
    id: 15,
    orderNumber:2000702909094,
    orderDatetime: "20200506113409",
    orderPerson:"보라돌이",
    realBuyer:"뽀",
    contactNumber:"010-1234-9444",
    realAmount:53500,
    paymentMethod:"신용카드",
    paymentStatus:"결제확인",
  },
  {
    id: 16,
    orderNumber:2000702909090,
    orderDatetime:"20200506113409",
    orderPerson:"홍길동",
    realBuyer:"유관순",
    contactNumber:"010-1234-9444",
    realAmount:53500,
    paymentMethod:"신용카드",
    paymentStatus:"결제확인",
  },
  {
    id: 17,
    orderNumber:2000702909091,
    orderDatetime:"20200506113409",
    orderPerson:"왕건",
    realBuyer:"궁",
    contactNumber:"010-1234-9444",
    realAmount:53500,
    paymentMethod:"신용카드",
    paymentStatus:"결제확인",
  },
  {
    id: 18,
    orderNumber:2000702909092,
    orderDatetime: "20200506113409",
    orderPerson:"아이유",
    realBuyer:"유인나",
    contactNumber:"010-1234-9444",
    realAmount:53500,
    paymentMethod:"신용카드",
    paymentStatus:"결제확인",

  },
  {
    id: 19,
    orderNumber:2000702909093,
    orderDatetime: "20200506113409",
    orderPerson:"뽀로로",
    realBuyer:"크롱",
    contactNumber:"010-1234-9444",
    realAmount:53500,
    paymentMethod:"신용카드",
    paymentStatus:"결제확인",
  },
  {
    id: 20,
    orderNumber:2000702909094,
    orderDatetime: "20200506113409",
    orderPerson:"보라돌이",
    realBuyer:"뽀",
    contactNumber:"010-1234-9444",
    realAmount:53500,
    paymentMethod:"신용카드",
    paymentStatus:"결제확인",
  },
  {
    id: 21,
    orderNumber:2000702909093,
    orderDatetime: "20200506113409",
    orderPerson:"뽀로로",
    realBuyer:"크롱",
    contactNumber:"010-1234-9444",
    realAmount:53500,
    paymentMethod:"휴대폰",
    paymentStatus:"결제확인",
  },
]
mock.onGet("/api/order/list").reply(200, {
  data
})
