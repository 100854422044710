import React from "react"
import * as Icon from "react-feather"


const navigationConfig = [
  /*dashboard*/
  {
    id: "dashboard",
    title: "Dashboard",
    type: "item",
    icon: <Icon.Home size={20} />,
    permissions: ["admin", "editor"],
    navLink: "" +
      "/"
  },
  {
    type: "groupHeader",
    groupTitle: "PAYMENT"
  },
  /*payment/partner*/
  {
    id: "partnermg",
    title: "가맹점 관리",
    type: "collapse",
    icon: <Icon.Layers size={20}/>,
    children: [
      // {
      //   id: "contract",
      //   title: "가맹점 계약 관리",
      //   icon: <Icon.Circle size={10} />,
      //   type: "item",
      //   permissions: ["admin", "editor"],
      //   navLink: "/partner/contract",
      //   disabled: true
      // },
      {
        id: "payment",
        title: "가맹점 요금제 관리",
        type: "item",
        icon: <Icon.Circle size={10} />,
        permissions: ["admin", "editor"],
        navLink: "/partner/payment"
      },
    ]
  },
  /*payment/order*/
  {
    id: "ordermg",
    title: "주문 관리",
    type: "collapse",
    icon: <Icon.Archive size={20}/>,
    children: [
      {
        id: "orderhistory",
        title: "주문 내역 조회",
        type: "item",
        icon: <Icon.Circle size={10} />,
        permissions: ["admin", "editor"],
        navLink: "/order/orderhistory"
      },
      {
        id: "payhistory",
        title: "결제 / 환불 내역 조회",
        type: "item",
        icon: <Icon.Circle size={10} />,
        permissions: ["admin", "editor"],
        navLink: "/order/payhistory"
      },
    ]
  },
  /*payment/client*/
  {
    id: "clientmg",
    title: "고객 관리",
    type: "collapse",
    icon: <Icon.User size={20}/>,
    children: [
      {
        id: "ticketuse",
        title: "승차권 사용 관리",
        type: "item",
        icon: <Icon.Circle size={10} />,
        permissions: ["admin", "editor"],
        navLink: "/client/ticketuse"
      },
      {
        id: "receivable",
        title: "승차권 미수금 조회",
        type: "item",
        icon: <Icon.Circle size={10} />,
        permissions: ["admin", "editor"],
        navLink: "/client/receivable",
        disabled: true
      },
      {
        id: "unpaid",
        title: "승차권 미납금 관리",
        type: "item",
        icon: <Icon.Circle size={10} />,
        permissions: ["admin", "editor"],
        navLink: "/client/unpaid",
      },
      {
        id: "refund",
        title: "관리자 환불 관리",
        type: "item",
        icon: <Icon.Circle size={10} />,
        permissions: ["admin", "editor"],
        navLink: "/client/refund",
        disabled: true
      },
    ]
  },
  /*payment/client*/
  {
    id: "settlementmg",
    title: "정산 관리",
    type: "collapse",
    icon: <Icon.CreditCard size={20}/>,
    children: [
      {
        id: "monthly",
        title: "학원 월별 정산",
        type: "item",
        icon: <Icon.Circle size={10} />,
        permissions: ["admin", "editor"],
        navLink: "/calculate/monthly",
      },
      {
        id: "unpaid",
        title: "학원 월별 미납금",
        type: "item",
        icon: <Icon.Circle size={10} />,
        permissions: ["admin", "editor"],
        navLink: "/calculate/unpaid",
      }
      ,
      {
        id: "schedule_count",
        title: "학원 월별 스케쥴",
        type: "item",
        icon: <Icon.Circle size={10} />,
        permissions: ["admin", "editor"],
        navLink: "/calculate/schedule",
      },
      {
        id: "student",
        title: "학원 월별 결제현황",
        type: "item",
        icon: <Icon.Circle size={10} />,
        permissions: ["admin", "editor"],
        navLink: "/calculate/student",
      },
    ]
  },
  {
    id: "stats",
    title: "통계",
    type: "collapse",
    icon: <Icon.BarChart size={20}/>,
    children: [
      {
        id: "calculate",
        title: "내부용 정산 통계",
        icon: <Icon.Circle size={10} />,
        type: "item",
        permissions: ["admin", "editor"],
        navLink: "/stats/calculate",
        disabled: true
      },
      {
        id: "academy",
        title: "학원별 정산 통계",
        icon: <Icon.Circle size={10} />,
        type: "item",
        permissions: ["admin", "editor"],
        navLink: "/stats/academy",
        disabled: false
      },
      {
        id: "accounting",
        title: "내부 회계용 통계",
        icon: <Icon.Circle size={10} />,
        type: "item",
        permissions: ["admin", "editor"],
        navLink: "/stats/accounting",
        disabled: false
      },
    ]
  },
  // /*payment/client*/
  // {
  //   id: "dispatch",
  //   title: "배차 관리",
  //   type: "collapse",
  //   icon: <Icon.Truck size={20}/>,
  //   children: [
  //     {
  //       id: "course_automation",
  //       title: "자동노선생성",
  //       type: "item",
  //       icon: <Icon.GitPullRequest size={15} />,
  //       permissions: ["admin", "editor"],
  //       navLink: "/dispatch/course/auto"
  //     },
  //   ]
  // },
  // /*track*/
  // {
  //   id: "track",
  //   title: "GPS tracking",
  //   type: "collapse",
  //   icon: <Icon.Truck size={20}/>,
  //   children: [
  //     {
  //       id: "track_courses",
  //       title: "지도",
  //       type: "item",
  //       icon: <Icon.GitPullRequest size={15} />,
  //       permissions: ["admin", "editor"],
  //       navLink: "/track/courses"
  //     }
  //   ]
  // },
]

export default navigationConfig
