import mock from "../mock"
const data = [
    {
        id: 1,
        partner: "와이즈만",
        area: "경기/분당",
        paymethod:"무제한 요금제",
        amount: 55000,
        datechangetime: "20200504",
    },
    {
        id: 2,
        partner: "수학의 아침",
        area: "경기/수내",
        paymethod:"편도 요금제",
        amount: 30000,
        datechangetime: "20200503",
    },
    {
        id: 3,
        partner: "수학의 아침",
        area: "경기/정자",
        paymethod:"무제한 요금제",
        amount: 57500,
        datechangetime: "20200426",
    },
    {
        id: 4,
        partner: "수학의 아침",
        area: "경기/수지",
        paymethod:"무제한 요금제",
        amount: 62000,
        datechangetime: "20200302",
    },
    {
        id: 5,
        partner: "몬스터 잉글리쉬",
        area: "서울/구의",
        paymethod:"편도 요금제",
        amount: 25000,
        datechangetime: "20200301",
    },
]
mock.onGet("/api/partner/list").reply(200, {
  data
})
